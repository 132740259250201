<template>
  <div>
    <pcHeaders></pcHeaders>

    <div class="banner">
      <div class="regBox">
        <div style="display: flex; justify-content: center">
          <div class="regTitle">
            <img src="../../assets/images/regTitle.png"
                 alt="" />
            <div style="position: relative; z-index: 2; text-align: center; line-height: 37px">用户注册中心</div>
          </div>
        </div>

        <div class="zhuti">
          <div class="formd">
            <el-form ref="form"
                     :model="form"
                     :rules="rules">
              <div class="bia">
                <div class="text">姓名</div>
                <el-form-item prop="realName">
                  <el-input v-model="form.realName"
                            class="inp"
                            style="width: 235px;"
                            placeholder="请输入姓名"></el-input>
                </el-form-item>
              </div>

              <div class="bia mr24">
                <div class="text">身份证号</div>
                <el-form-item prop="cardNo">
                  <el-input class="inp"
                            @blur="jy(1)"
                            v-model="form.cardNo"
                            placeholder="请输入身份证号"></el-input>
                </el-form-item>
              </div>

              <div class="bia mr24">
                <div class="text">工作地区</div>
                <el-form-item prop="area">
                  <el-cascader v-model="form.area"
                               :options="optionsArea"
                               :props="props"
                               style="width: 235px;"></el-cascader>
                </el-form-item>
              </div>

              <div class="bia mr24">
                <div class="text">手机号</div>
                <el-form-item prop="bindMobile">
                  <el-input v-model="form.bindMobile"
                            @blur="jy(2)"
                            class="inp"
                            placeholder="请输入手机号"></el-input>
                </el-form-item>
              </div>

              <div class="bia mr24">
                <div class="text">验证码</div>
                <el-form-item prop="imgCode">
                  <el-input v-model="form.imgCode"
                            class="codeinp"
                            placeholder="右侧验证码"></el-input>
                </el-form-item>

                <img @click="getImgCode"
                     class="code"
                     style="width: 95px;"
                     :src="codeImg"
                     alt="" />
              </div>

              <div class="bia mr24">
                <div class="text">手机验证码</div>
                <el-form-item prop="mobileCode">
                  <el-input class="codeinp"
                            v-model="form.mobileCode"
                            placeholder="手机验证码"></el-input>
                </el-form-item>
                <div class="codeBtn"
                     @click="getCode"
                     v-if="!isCounting">{{ buttonText }}</div>
                <div class="codeBtn"
                     v-if="isCounting">{{ countdown }}s后重新发送</div>
              </div>

              <div class="bia mr24">
                <div class="text">设置密码</div>
                <el-form-item prop="userPasswd">
                  <el-input class="inp"
                            v-model="form.userPasswd"
                            placeholder="请输入密码"
                            show-password></el-input>
                </el-form-item>
              </div>
              <el-button class="regBtn mr24"
                         @click="submitReg('form')">立即注册</el-button>
            </el-form>

            <div class="regBottom">
              <div @click="qall">
                <div style="display: flex; cursor: pointer">
                  <div>
                    <img v-if="!allIn"
                         src="../../assets/images/noSlet.png"
                         alt="" />
                    <img v-else
                         src="../../assets/images/slet.png"
                         alt="" />
                  </div>
                  <div class="text1"
                       @click="dialogVisible = true">已阅读并同意《注册协议》</div>
                </div>
              </div>

              <div class="text2"
                   @click="goBack">返回登录</div>
            </div>
          </div>

          <div class="rgt">
            <div class="rgtcontent">
              <div style="text-align: center"
                   class="textTips">温馨提示</div>

              <div class="textnrn">
                <div>1.请仔细核对姓名和身份证，注册成功后不允许自行更改，如有错误请联系客服。</div>
                <div>
                  2.手机号码是您账号服务的重要凭证，找回密码和客户服务等都需要通过手机短信验证，请勿泄漏给第三方，手机号码更换请及时在网站更新。
                </div>
              </div>
              <div>
                <img src="../../assets/images/tegbc.png"
                     alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible"
               width="80%">
      <xieyi></xieyi>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import xieyi from './component/xieyi.vue';
import { mapMutations } from 'vuex';
import { toBindMobileCheck, toIdCardCheck, sendDx, doRegister, getAllAreaList, captchaImage } from '@/api/regist.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom,
    xieyi
  },
  data() {
    //身份证校验
    var checkCardNo = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入身份证号'));
      }
      var regExp = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (regExp.test(value)) {
      } else {
        return callback(new Error('身份证号码不符合规则'));
      }
    };

    //手机校验
    var checkbindMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号'));
      }
      var regExp = /^(13\d|14|15[^4\D]|17[^49\D]|18\d)\d{8}$/;
      if (regExp.test(value)) {
      } else {
        return callback(new Error('手机号码不符合规则'));
      }
    };

    // 这里存放数据
    return {
      allIn: false, //是否勾选
      form: {
        cardNo: '',
        realName: '',
        area: [],
        imgCode: '',
        mobileCode: '',
        userPasswd: '',
        bindMobile: ''
      },
      rules: {
        realName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        cardNo: [{ validator: checkCardNo, trigger: 'blur' }],
        bindMobile: [{ validator: checkbindMobile, trigger: 'blur' }],
        imgCode: [{ required: true, message: '请输入图片验证码', trigger: 'blur' }],
        mobileCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
        userPasswd: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        area: [{ required: true, message: '请选择地区', trigger: 'change' }]
      },
      props: {
        value: 'CODEID',
        label: 'CITYNAME'
      },
      optionsArea: [],
      codeImg: '',
      uuid: '',
      dialogVisible: false,
      phone: false,
      card: false,
      countdown: 60, // 默认倒计时为60秒
      isCounting: false, // 标记是否正在进行倒计时
      buttonText: '获取验证码', // 按钮文字
      timerId: null,
      regitFalg: true //表单限制多次提交
    };
  },

  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    ...mapMutations('m_user', ['updateToken', 'updateName', 'REAL_NAME']),
    //注册提交
    submitReg(formName) {
      if (this.form.realName == '') {
        this.$message('请输入姓名！');
        return false;
      }
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (reg.test(this.form.cardNo) === false) {
        return false;
      }
      if (this.form.area == '') {
        this.$message('请选择地区');
        return false;
      }
      if (this.form.bindMobile == '') {
        this.$message('请输入手机号');
        return false;
      }
      if (this.form.bindMobile.length !== 11) {
        this.$message('手机号格式不正确');
        return false;
      }
      if (this.form.imgCode == '') {
        this.$message('请输入图片验证码');
        return false;
      }
      if (this.form.userPasswd == '') {
        this.$message('请输入注册密码');
        return false;
      }
      if (this.form.mobileCode == '') {
        this.$message('请输入手机验证码');
        return false;
      }
      if (
        this.form.realName !== '' ||
        this.form.cardNo !== '' ||
        this.form.bindMobile !== '' ||
        this.form.imgCode !== '' ||
        this.form.mobileCode !== '' ||
        this.form.userPasswd !== ''
      ) {
        //必须勾选协议
        if (this.allIn) {
          if (this.card && this.phone) {
            if (Array.isArray(this.form.area) && this.form.area.length) {
              this.form.area = this.form.area[this.form.area.length - 1];
            }
            this.form.logo = this.uuid;
            if (this.regitFalg) {
              this.regitFalg = false;
              doRegister(this.form).then(res => {
                console.log(res);
                if (res.data.data.code !== '1') {
                  this.$message({
                    message: res.data.data.msg,
                    type: 'warning'
                  });
                  this.buttonText = '获取验证码';
                  this.isCounting = false;
                  this.getImgCode();
                  this.regitFalg = true;
                  return false;
                } else {
                  this.$message({
                    message: '注册成功！',
                    type: 'success'
                  });
                  this.regitFalg = true;
                  this.updateToken(res.data.data.token);
                  this.updateName(res.data.data.userName);
                  // 拿到密钥存在本地存储
                  localStorage.setItem('token', res.data.data.token);
                  localStorage.setItem('userName1', res.data.data.userName);
                  this.REAL_NAME(res.data.data.userName);
                  this.$router.push('/studyMain');
                }
              });
            }
          }
        } else {
          this.$message('请勾选注册协议!');
          return false;
        }
      } else {
        this.$message('请填写完全信息!');
        return false;
      }
    },
    jy(id) {
      if (id == 1) {
        let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (reg.test(this.form.cardNo) === false) {
          // this.$message('身份证输入不合法');
          return false;
        }
        toIdCardCheck({ cardNo: this.form.cardNo }).then(res => {
          console.log(res);
          if (res.data.data.msg == 1) {
            this.$message({
              message: '身份证号码已注册！',
              type: 'warning'
            });
            this.card = false;
            return false;
          } else if (res.data.data.msg == 0) {
            this.card = true;
            return false;
          }
        });
      } else {
        if (this.form.bindMobile == '') {
          return false;
        }
        toBindMobileCheck({ bindMobile: this.form.bindMobile }).then(res => {
          console.log(res);
          if (res.data.data.msg == 1) {
            this.phone = false;
            this.$message({
              message: '手机号码已注册！',
              type: 'warning'
            });
            this.phone = false;
            return false;
          } else {
            this.phone = true;
            return false;
          }
        });
      }
    },
    //获取手机短信
    getCode() {
      if (this.form.realName == '') {
        this.$message('请输入姓名！');
        return false;
      }
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (reg.test(this.form.cardNo) === false) {
        this.$message('身份证输入不合法');
        return false;
      }
      if (this.form.area == '') {
        this.$message('请选择地区');
        return false;
      }
      if (this.form.bindMobile == '') {
        this.$message('请输入手机号');
        return false;
      }
      if (this.form.bindMobile.length !== 11) {
        this.$message('手机号格式不正确');
        return false;
      }
      if (this.form.imgCode == '') {
        this.$message('请输入图片验证码');
        return false;
      }

      if (this.phone) {
        //先清除倒计时的定时器
        this.stopCountdown();
        let query = {
          bindMobile: this.form.bindMobile,
          imgCode: this.form.imgCode,
          logo: this.uuid
        };
        sendDx(query).then(res => {
          console.log(res);
          if (res.data.data.code == 1) {
            //倒计时逻辑
            if (!this.isCounting) {
              this.startCountDown();
              // this.getImgCode();
              // 这里可以写发送验证码的逻辑
              console.log('发送验证码');
            }
          } else {
            this.$message({
              message: res.data.data.msg,
              type: 'warning'
            });
            this.getImgCode();
            return false;
          }
        });
      } else {
        this.$message('手机号码已被注册！');
        return false;
      }
    },
    startCountDown() {
      this.timerId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timerId);

          this.resetButtonState();
        }
      }, 1000);

      this.isCounting = true;
    },
    stopCountdown() {
      clearInterval(this.timerId);
    },
    resetButtonState() {
      this.isCounting = false;
      this.countdown = 60;
      this.buttonText = '获取验证码';
    },
    //获取图片验证码
    getImgCode() {
      captchaImage().then(res => {
        this.codeImg = 'data:image/png;base64,' + res.data.img;
        this.uuid = res.data.uuid;
      });
    },
    qall() {
      this.allIn = !this.allIn;
    },
    goBack() {
      this.$router.push('/login');
    },
    //获取数据
    getAllAreaList() {
      getAllAreaList({}).then(res => {
        this.optionsArea = res.data.data;
      });
      captchaImage().then(res => {
        this.codeImg = 'data:image/png;base64,' + res.data.img;
        this.uuid = res.data.uuid;
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAllAreaList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.el-form-item {
  margin-bottom: 0 !important;
}
.banner {
  width: 100%;
  background: #f5f5f5;
  padding-top: 48px;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  font-family: Regular;
  .regBox {
    width: 900px;
    height: 600px;
    background: #ffffff;
    opacity: 1;
    border-radius: 0px;
    .regTitle {
      width: 266px;
      position: relative;
      font-size: 16px;
      color: #ffffff;
      img {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
      }
    }
    .zhuti {
      margin-top: 35px;
      display: flex;
      .formd {
        width: 50%;
        padding-left: 100px;
        .mr24 {
          margin-top: 24px;
        }
        .bia {
          display: flex;
          .text {
            font-family: Medium;
            width: 90px;
            font-size: 14px;
            line-height: 36px;
          }
          .inp {
            width: 235px;
            height: 36px;
            opacity: 1;
            border-radius: 4px;
          }
          .codeinp {
            width: 130px;
            height: 36px;
          }
          .code {
            width: 80px;
            height: 40px;
            border-radius: 4px;
            margin-left: 10px;
            cursor: pointer;
          }
          .codeBtn {
            width: 95px;
            height: 40px;
            opacity: 1;
            border-radius: 4px;
            margin-left: 10px;
            font-size: 11px;
            text-align: center;
            border: 1px solid #4076f6;
            color: #4076f6;
            line-height: 40px;
            cursor: pointer;
          }
        }
        .regBtn {
          width: 325px;
          height: 40px;
          background: #4076f6;
          opacity: 1;
          border-radius: 4px;
          color: #ffffff;
        }
        .regBottom {
          width: 325px;
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          img {
            margin-top: 3px;
          }
          .text1 {
            font-size: 14px;
            color: #a3a3a3;
            margin-left: 5px;
          }
          .text2 {
            font-size: 14px;
            color: #2878ff;
            cursor: pointer;
          }
        }
      }
      .rgt {
        width: 50%;
        padding-left: 92px;

        .rgtcontent {
          width: 250px;
          height: 501px;
          background: linear-gradient(180deg, #d8e3fc 0%, #ffffff 100%);
          opacity: 1;
          border-radius: 8px;
          padding-top: 30px;
          color: #606060;
          .textTips {
            font-size: 16px;
            color: #606060;
          }
          .textnrn {
            padding: 0 20px;
            margin-top: 20px;
            font-size: 14px;
            line-height: 25px;
          }
        }
      }
    }
  }
}
</style>
