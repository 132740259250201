<template>
  <div>
    <div class="conDetail">
      <h4 style="text-align: center;">安徽继续教育网用户注册服务协议</h4>

      <p>
        尊敬的用户：<br>
        欢迎您使用安徽继续教育网，本网站是经安徽省人力资源和社会保障厅授权，并获得安徽省省级专业技术人员继续教育基地资质的在线学习服务平台，由安徽冠成教育科技有限公司负责建设、管理和运营。本网站的所有内容、资讯、资源、网络产品及服务，均由本网站建设、管理和经营人享有相应的著作权、经营权及其他所有合法权益。
        在您（以下简称用户为“您”）注册为本网站会员前，请您务必仔细阅读《安徽继续教育网用户注册服务协议》（以下简称“本协议”）。本协议为您与安徽继续教育网达成的网站及网络产品及服务等内容的使用协议，一旦您同意注册，即视为您同意并遵守本协议的全部条款，本协议即对您和安徽继续教育网都具有法律约束力。如您不同意本协议内容，请不要注册为本网站会员且不要使用本网站、资源、网络产品及服务等。您同意本协议并按照本网站的注册程序完成注册后，方可成为本网站会员，方可购买本网站产品、服务及通过本网站论坛等发布合法信息。

      </p>
      <br>
      <p>
        一、用户承诺 <br>
        您同意并承诺做到以下几点：<br>
        （一）不发表任何与法律、法规及公序良俗相违背的内容，包括但不限于<br>
        1、反对宪法所确定的基本原则的；<br>
        2、危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br>
        3、损害国家荣誉和利益的；<br>
        4、煽动民族仇恨、民族歧视，破坏民族团结的；<br>
        5、破坏国家宗教政策，宣扬邪教和封建迷信的；<br>
        6、散布谣言，扰乱社会秩序，破坏社会稳定的；<br>
        7、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br>
        8、侮辱或者诽谤他人，侵害他人合法权益的；<br>
        9、含有法律、行政法规禁止的其他内容的。<br>
        （二）承诺发表的内容中不含有任何侵害他人姓名权、肖像权、隐私权、著作权或其他合法权益的内容，不上传任何广告或促销信息，或任何带有贬损或损害性特征的内容，不粘贴或传播带有病毒的信息，不利用软件或网络系统漏洞破坏干扰本网站网络服务，否则本网站有权删除相关内容且由此产生的法律责任及相关费用（包括但不限于软硬件费用、维修费用、诉讼或仲裁费用、向相关权利人赔偿的费用、给本网站造成的损失等等）全部由您个人承担，本网站不承担任何法律责任。<br>
        （三）您同意本网站可以永久性地对您上传的内容进行审核、整理、修改、复制、传播；您同意本网站删除您发表的、可能侵害他人姓名权、肖像权、隐私权、著作权或其他合法权益的内容以及其他有害或违规内容，且您保证不重复发表上述内容；您有义务证明您发表的内容没有侵犯任何第三方的任何合法权益。<br>
        （四）尊重本网站及网络产品的著作权及其他合法权益，仅以个人学习目的使用本网站、购买本网站的网络产品及接受服务，并保证不用于任何调查、广告或其他商业目的，不用于任何非法目的。<br>
        （五）您违背上述承诺之一的，均为严重违约行为，为防止损害扩大，本网站有权自行采取救济行为，包括但不限于删除您的账户信息、禁止您继续使用本网站及网络产品、停止提供服务等，而无需对用户或任何第三方承担任何责任。<br>

        （六）您同意安徽继续教育网仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。<br>
        （七）您同意安徽继续教育网有权在提供网络服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息（包括但不限于在安徽继续教育网网站的任何页面上投放广告），并且，用户同意接受安徽继续教育网通过电子邮件或其他方式向用户发送商品促销或其他相关商业信息。　　<br>
        （八）您同意安徽继续教育网会员升级规则和积分规则作为本用户注册服务协议的不可分割部分，同意注册协议即视为同意会员升级规则和积分规则，请在注册前仔细阅读会员升级规则和积分规则，您可以从帮助中心查找到会员升级规则和积分规则。<br>
        （九）保护用户隐私是安徽继续教育网的一项基本政策，安徽继续教育网保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在安徽继续教育网的非公开内容，但下列情况除外：事先获得用户的明确授权；根据有关的法律法规要求；按照相关政府主管部门的要求；为维护社会公众的利益；为维护安徽继续教育网的合法权益。<br>
        <br>二、用户的权利和义务<br>
        （一）用户注册时应提供详尽、准确的个人资料并及时更新，以便本网站及时与您取得联系。您注册后应牢记您的账号和密码，您有权随时查询、更改或更新注册信息。在本网站注册后，您的账号可同时应用于本网站论坛。您在论坛中的所有言论和行为同样受到本协议的约束。<br>
        （二）无论用户注册与否，均可免费体验本网站提供的部分免费资源、网络产品和服务，但此免费体现仅限于在线浏览，您无权对其转载、链接、下载、复制、修改等。由于网络在线浏览的及时性和不可控性，请您在购买产品或者服务前务必试用免费资源，待满意后再请购买；一旦您付款购买，将不作退货处理。您注册后可在选课中心模块选择您要购买的网络产品或者服务，网络产品使用权的有效期为一年。此购买仅仅是网络产品在线浏览权的购买，网络产品的著作权及其他合法权益仍归原权利人享有。除本网站特别标明或书面授权许可外，您无权下载、复制、刻录、录屏、传播、发表、出售、出租、编译、反编译网站的任何内容或演绎衍生出其他作品。否则，本网站有权封存相关用户ID并追究相关法律责任。<br>
        （三）本网站及其任何组成部分，包括但不限于网站页面设计、编排方式、技术程序、软件、资源、网络产品、产品或服务名称、文字、视音频、图片、电子书、实验、Logo、商标、标识、著作权等均由本网站所有，均受到著作权法、商标法及其他法律法规的保护。除本网站特别标明或书面授权许可外，任何媒体、网站或个人未经本网站正式书面授权，不得转载、链接、转贴、修改、复制、下载、出售、演绎、反向编译或以其他方式复制、发布或发表上述内容，不得将之用于任何营利或非营利目的，不得以个人学习为名实施任何侵害本网站权益的行为；已经本网站书面授权的媒体、网站或个人在使用时必须注明“视频/图片/文字来源：安徽继续教育网”，且不得擅自允许他人继续转载或复制，不得出售或用于商业目的。违反上述规定或有其他侵权行为的，本网站权利人保留追究侵权人法律责任的权利。<br>
        （四）如您发现本网站其他用户有侵犯您姓名权、肖像权、隐私权、著作权或其他合法权益现象的，请及时与本网站联系并附加相关权利证明文件，以便本网站及时作出处理，维护您的合法权益。<br>
        （五）本网站本着严谨负责的态度对网络产品质量精益求精，但不保证无任何错误或瑕疵，且不会因个别错误而退货，请您在购买前务必慎重考虑；如您购买本网站产品及服务的，即视为同意并接受上述观点。<br>
        （六）用户为未成年人的，其监护人应承担保护未成年人在网络环境下隐私权的责任。因网络的特殊性，本网站无法辨别交易主体的民事权利和行为能力、资质、信用等状况，故本网站一律将交易主体视为成年人或未成年人的监护人。<br>
        （七）本网站重视用户个人隐私的保护，但您也有义务保证密码和帐号的安全，您应对利用该帐号和密码所进行的活动承担相应法律责任。如您发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管。一个账户只能在一台电脑上唯一性登录，不可由多用户同时登录。账号注册后一年内未登录的，本网站有权删除该账号。<br>
        （八）安徽继续教育网可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与安徽继续教育网同等的保护用户隐私的责任，则安徽继续教育网有权将用户的注册资料等提供给该第三方。<br>
        （九）鉴于网络服务的特殊性，用户同意安徽继续教育网有权随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。如变更、中断或终止的网络服务属于免费网络服务，安徽继续教育网无需通知用户，也无需对任何用户或任何第三方承担任何责任；如变更、中断或终止的网络服务属于收费网络服务，安徽继续教育网应当在变更、中断或终止之前事先通知用户，并应向受影响的用户提供等值的替代性的收费网络服务，如用户不愿意接受替代性的收费网络服务，就该用户已经向安徽继续教育网支付的服务费，安徽继续教育网应当按照该用户实际使用相应收费网络服务的情况扣除相应服务费之后将剩余的服务费退还给该用户。<br>
        （十）安徽继续教育网有权随时修改本协议的任何条款，一旦本协议的内容发生变动，安徽继续教育网将会直接在安徽继续教育网网站上公布修改之后的协议内容，该公布行为视为安徽继续教育网已经通知用户修改内容。安徽继续教育网也可通过其他适当方式向用户提示修改内容。<br>
        （十一）本协议项下安徽继续教育网对于用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。<br>
        （十二）不得以任何形式使用安徽继续教育网网络服务侵犯安徽继续教育网的商业利益，包括并不限于发布非经安徽继续教育网许可的商业广告；不得利用安徽继续教育网网络服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；不得利用安徽继续教育网提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料。<br>
        （十三）用户同意遵守《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《信息网络传播权保护条例》等有关计算机及互联网规定的法律、法规、实施办法。在任何情况下，安徽继续教育网合理地认为用户的行为可能违反上述法律、法规，安徽继续教育网可以在任何时候，不经事先通知终止向该用户提供服务。<br>
        （十四）直到您发出订单，我们才能确认商品的价格。尽管我们做出最大的努力，我们的商品目录里的一小部分商品可能会有定价错误。如果我们发现错误定价，我们将采取下列之一措施：1)如果某一商品的正确定价低于我们的错误定价，我们将按照较低的定价向您销售交付该商品。2)如果某一商品的正确定价高于我们的错误定价，我们会根据我们的情况决定，是否联系您寻求您的指示,
        或者取消订单、终止报名并通知您。<br>
        <br>三、免责声明<br>
        （一）由于计算机设备的多样性和复杂性，本网站不保证所提供的视频在每一个Windows系统上运行时不出现任何问题，不担保可以和任何程序同时使用、运行而不发生冲突。如出现上述现象，用户应立即检测相关设备及程序，防止造成任何损失。<br>
        （二）因地震、台风、洪水、火灾、战争、政府禁令以及其他不可抗力或互联网黑客攻击事件、用户自身疏忽管理等原因，致使影响网站、网络产品及服务使用的，本网站不承担任何法律责任。<br>
        （三）对于本网站所提供的学习内容，我们力求精益求精，但由于能力有限及理解力的不同，可能会存在纰漏之处。如果您使用本网站，表明您己经对我们工作主体部分的认可和宽容我们工作中可能存在的纰漏。<br>
        （四）本网站承诺保护注册用户的隐私，但经您授权披露或您自动泄露个人信息而导致自身隐私泄露或账户被窃取的，以及因公安、行政、司法等部门执行公务而必须公布您的信息的，本网站不承担任何法律责任。<br>
        （五）为方便您使用，本网站会提供一些第三方网站或资源的链接，除另有声明外，这些链接仅为提供便利，本网站无法对第三方网站服务进行控制，请您浏览时慎重选择。<br>
        您的账户为您自行设置并由您保管，安徽继续教育网任何时候均不会主动要求您提供您的账户。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开安徽继续教育网平台。账户因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。<br>
        （六）本网站服务可能会提供与其他国际互联网网站或资源进行链接。对于前述网站或资源是否可以利用，安徽继续教育网承担担保责任。因使用或依赖上述网站或资源所生的损失或损害，安徽继续教育网也不负担任何责任。<br>
        （七）安徽继续教育网如因系统维护或升级而需暂停服务时，将事先公告。若因硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，安徽继续教育网不承担任何责任。<br>
        （八）请清楚准确地填写您的真实姓名、送货地址及联系方式。因如下情况造成订单延迟或无法配送等，安徽继续教育网将无法承担迟延配送的责任：　　<br>
        （九）您提供的信息错误或不够详细；货物送达无人签收，及由此造成的重复配送所产生的费用及后果；不可抗力，例如：自然灾害、交通戒严、罢工、骚乱、政府行为、突发战争等。数字商品无需送货，在您下单购买并支付相应价款后，安徽继续教育网将进行订单处理，使您可以在电脑上浏览。　　<br>
        （十）用户理解，安徽继续教育网需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，安徽继续教育网无需为此承担任何责任，但安徽继续教育网应尽可能事先进行通告。　<br>
        （十一）用户理解，安徽继续教育网针对某些特定的安徽继续教育网网络服务的使用通过各种方式（包括但不限于网页公告、电子邮件、短信提醒等）作出的任何声明、通知、警示等内容视为本协议的一部分，用户如使用该等安徽继续教育网网络服务，视为用户同意该等声明、通知、警示的内容。<br>
        （十二）用户明确同意其使用安徽继续教育网网络服务所存在的风险将完全由其自己承担；因其使用安徽继续教育网网络服务而产生的一切后果也由其自己承担，安徽继续教育网对用户不承担任何责任。安徽继续教育网不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。<br>
        <br>四、法律适用及管辖<br>
        本协议的订立、执行、解释及争议的解决均适用中华人民共和国法律。<br>
        通过非本网站法定渠道或方式获取本网站权益的，本网站有权追缴非法所得；造成负面影响的，将保留追究其责任的权利。<br>
        因本网站、网络产品或者服务的购买和使用等发生纠纷的，应友好协商解决，协商不成的，交由合肥仲裁委员会仲裁。<br>
        再次郑重提示：如果您不接受本协议，请不要点击“同意”按钮；一旦您点击“同意”按钮并完成注册的，即视为您同意并遵守本协议所包含的全部条款。<br>

      </p>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {};
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style>
/* //@import url(); 引入公共css类 */
</style>